<template>
  <TransitionRoot appear :show="open">
    <Dialog
      static
      class="fixed inset-0 z-40 max-lg:mx-2"
      :open="open"
      @close="!closingDisabled && $emit('close')"
    >
      <div ref="modal" class="flex h-[100dvh] items-center justify-center">
        <TransitionChild
          enter="transition-opacity ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-in duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-black opacity-30" />
        </TransitionChild>
        <TransitionChild
          class="relative z-40 mx-auto flex max-h-[var(--total-height)] w-full max-w-lg flex-col max-md:w-screen md:max-h-[90vh]"
          enter="transform transition ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transform transition ease-in duration-300"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
          v-bind="$attrs"
        >
          <DialogTitle
            class="text-18-px static top-0 grid grid-cols-5 grid-rows-1 items-center rounded-t bg-wild-sand text-center"
          >
            <div class="col-start-2 col-end-5 font-bold md:col-start-2">
              <slot name="title" />
            </div>
            <button
              v-if="!closingDisabled"
              v-ripple="{ color: 'blue' }"
              class="m-2 ml-auto rounded-full p-2 transition hover:bg-gray-200"
              @click="$emit('close')"
            >
              <i-heroicons-x-mark-20-solid class="h-5 w-5" />
            </button>
          </DialogTitle>
          <div
            class="grow overflow-y-auto bg-white px-6"
            :class="{
              'py-4': !noPaddingY,
            }"
          >
            <slot />
          </div>
          <slot name="footer">
            <div class="rounded-b bg-white p-2"></div>
          </slot>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<script lang="ts" setup>
defineEmits<{
  close: [];
}>();

const props = withDefaults(
  defineProps<{
    open: boolean;
    closingDisabled?: boolean;
    noPaddingY?: boolean;
    fullscreenOnMobile?: boolean;
  }>(),
  { closingDisabled: false, noPaddingY: false, fullscreenOnMobile: false }
);

const { vRipple } = useVRipple();

const { modal } = storeToRefs(useModalStore());

useLockBackground(() => props.open);
</script>
